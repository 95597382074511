<template>
    <b-overlay :show="showLoader">
        <b-card>
            <b-button variant="primary" @click="$router.push({name: 'add-bulletin'})" v-if="$hasPermissions($permissions.EditHabitBulletin) || $hasPermissions($permissions.EditStaninvestBulletin)">Dodaj obvestilo</b-button>
            <b-alert class="mt-1 p-1 text-center" show variant="warning" v-if="hasBeenDeleted">Po brisanju je potrebnih nekaj sekund, da je brisanje izvedeno. Če je oglas po brisanju še vedno viden, ponovno naložite stran.</b-alert>
            <Table ref="table" class="mt-1" :fields="fields" route="/api/management/v1/bulletin" :fromElastic="true">
                <template #cell(title)="row">
                    <div class="content-table" v-html="row.item.title"/>
                </template>
                <template #cell(content)="row">
                    <div class="content-table" style="max-width: 300px;" v-html="row.item.content"/>
                </template>
                <template #cell(documents)="row">
                    <div v-if="row.item.documents.length > 0" class="content-table">{{row.item.documents.map(x => x.split('_')[1]).join(', ')}}</div>
                    <div v-else>/</div>
                </template>
                <template #cell(date_added)="row">
                    <div>{{ moment(row.item.date_added).format('DD.MM.YYYY') }}</div>
                </template>
                <template #cell(active)="row">
                    <b-badge v-if="row.item.active" variant="light-success">
                        Da
                    </b-badge>
                    <b-badge v-else variant="light-danger">
                        Ne
                    </b-badge>
                </template>
                <template #cell(actions)="row">
                    <div class="button-grid" v-if="(row.item.managers === 'habit' && $hasPermissions($permissions.EditHabitBulletin)) || (row.item.managers === 'staninvest' && $hasPermissions($permissions.EditStaninvestBulletin))">
                        <b-button variant="warning" @click="$router.push({name: 'edit-bulletin', params: {'bulletin_id': row.item.id}})"><fa icon="edit"/></b-button>
                        <b-button @click="deleteBulletin(row.item.id)" variant="danger"><fa icon="trash"/></b-button>
                    </div>
                </template>
            </Table>
        </b-card>
    </b-overlay>
</template>
<script>
    import {BOverlay, BButton, BBadge, BCard, BAlert} from 'bootstrap-vue'
    import Table from '@/views/Components/Table'
    export default {
        components: {
            Table,
            BOverlay,
            BButton,
            BBadge,
            BCard,
            BAlert
        },
        data() {
            return {
                showLoader: false,
                fields: [
                    { key: 'title', label: 'Naslov', sortable: true, class: 'text-center' },
                    { key: 'content', label: 'Opis', sortable: true, class: 'text-center' },
                    { key: 'documents', label: 'Dokumenti', sortable: true, class: 'text-center' },
                    { key: 'date_added', label: 'Datum', sortable: true, class: 'text-center', type: 'date' },
                    { key: 'active', label: 'Aktivno', sortable: true, class: 'text-center', type: 'bool' }
                ],
                hasBeenDeleted: false
            }
        },
        methods: {
            deleteBulletin(id) {
                this.$bvModal.msgBoxConfirm('Prosimo potrdite, da želite izbrisati oglas.', {
                    size: 'sm',
                    okTitle: 'Da',
                    cancelTitle: 'Ne',
                    hideHeaderClose: false,
                    okVariant: 'danger',
                    cancelVariant: 'outline-secondary',
                    centered: true
                }).then(value => {
                    if (value) {
                        const thisIns = this
                        thisIns.showLoader = true
                        this.$http.delete(`/api/management/v1/bulletin/${id}`)
                            .then(() => {
                                thisIns.$printSuccess('Obvestilo je izbrisano')
                                thisIns.hasBeenDeleted = true
                                setTimeout(() => {
                                    thisIns.hasBeenDeleted = false
                                }, 10_000)
                              
                                setTimeout(() => {
                                    thisIns.$refs.table.search()
                                }, 1000)
                            }).catch((error) => {
                                thisIns.$printError(error.response.data)
                            }).finally(function() {
                                thisIns.showLoader = false
                            })
                    }
                })
            }
        },
        mounted() {
            if (this.$hasPermissions(this.$permissions.EditHabitBulletin) || this.$hasPermissions(this.$permissions.EditStaninvestBulletin)) {
                this.fields.push({ key: 'actions', label: '', class: 'text-center' })
            }
        }
    }
</script>
<style scoped>
.button-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
}
.content-table {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>